import React, { useState, useEffect } from "react";

function App() {
  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 5) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container">
        <div
          className="header"
          style={{
            color: showBackground ? "rgb(141, 218, 192)" : "#343434",
          }}
        >
          <h1>Alastair Farman</h1>
          <div>Photography Retouch 3D Javascript & React Development</div>
        </div>
        <video className="video" autoPlay muted loop playsInline>
          <source src="./media/kickflip-tr.webm" type="video/webm" />
          <source src="./media/kickflip-tr.mp4" type="video/mp4;codecs=hvc1" />
        </video>
        <div
          className="hidden"
          style={{
            transform: showBackground ? "translateY(-0%)" : "translateY(-100%)",
          }}
        ></div>
        <div
          className="temp"
          style={{
            color: showBackground ? "rgb(141, 218, 192)" : "#343434",
          }}
        >
          Portfolio site in development - currently Photography Lead at Harrods
        </div>
      </div>
    </>
  );
}

export default App;
